<template>
  <div class="cr-todo-workspace-list-wrapper">
    <div>
      <!-- 워크스페이스 추가버튼 -->
      <div class="d-flex justify-end align-center">
        <AddWorkspaceBtn ref="addBtn" />
      </div>

      <!-- 워크스페이스 목록 화면 -->
      <v-expansion-panels accordion flat multiple v-model="oepnPanels">
        <v-expansion-panel v-for="(panel, i) in panels" :key="i">
          <!-- 워크스페이스 설명 -->
          <v-expansion-panel-header
            v-show="showOtherWorkspaces(panel.prefix)"
            :class="i == 1 ? 'mt-5' : ''"
          >
            <div class="d-flex align-center text-h5 font-weight-regular">
              {{ panel.title }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="20" class="ml-2">
                    mdi-information-outline
                  </v-icon>
                </template>
                <div class="d-flex justify-center caption text-pre-wrap">
                  {{ panel.description }}
                </div>
              </v-tooltip>
            </div>
          </v-expansion-panel-header>

          <!-- 워크스페이스 목록 -->
          <v-expansion-panel-content v-show="showOtherWorkspaces(panel.prefix)">
            <v-row class="ma-0 pb-4">
              <v-col
                v-for="(workspace, idx) in workspaces(panel.prefix)"
                :key="idx"
                cols="12"
                sm="6"
                class="mt-3 pa-0 cr-workspace-col"
                @click="routeWorkspace({ workspaceId: workspace.id })"
              >
                <div :class="workspaceCls(idx, panel.prefix)">
                  <div class="d-flex align-center" style="min-width: 0px;">
                    <v-avatar
                      size="38"
                      :color="workspace.emoji ? 'transparent' : workspace.color"
                      :class="
                        workspace.emoji ? 'cr-workspace-avatar-emoji' : ''
                      "
                    >
                      <span
                        class="text-h7"
                        :style="`color: ${invertColor(workspace.color)};`"
                      >
                        {{ workspace.avatar }}
                      </span>
                    </v-avatar>
                    <div class="d-flex flex-column pr-2 cr-todo-title ml-2">
                      <span
                        class="d-inline-block text-truncate"
                        style="height: 30px;"
                      >
                        {{ workspace.title }}
                      </span>
                      <span>
                        {{ workspace.ownerName }}
                        <span v-show="workspace.followerCnt > 0">
                          외 {{ workspace.followerCnt }}명
                        </span>
                      </span>
                    </div>
                  </div>
                  <v-btn
                    icon
                    @click.stop="e => menuClick(e, workspace, panel.prefix)"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col v-if="isEmptyWorkspaces(panel.prefix)">
                워크스페이스가 없습니다.
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div
        v-if="
          isEmptyWorkspaces('my') &&
            !showOtherWorkspaces('other') &&
            !useWorkflow
        "
        class="mx-auto mt-8 mb-8 pa-2 pb-1 cr-tutorial-todo-starter"
        style="position:relative;"
      >
        <img :src="require(`@/todo/assets/workspace.png`)" />
        <div
          class="my-1"
          style="position:absolute;  bottom:38px; left:38px; right:38px;"
        >
          <v-btn
            block
            depressed
            height="70"
            color="primary"
            @click="clickAddWorkspaceBtn"
            style="border-radius:16px; font-size:20px;"
          >
            <b>내 워크스페이스</b> 만들기
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-workspace-list-wrapper {
  height: calc(100% - 30px);
  margin-top: 30px;
  overflow: hidden;
  overflow-y: auto;

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1600px;
    margin: 0 auto;
    width: 70%;

    .v-expansion-panels ::v-deep {
      .v-expansion-panel-header,
      .v-expansion-panel-header.v-expansion-panel-header--active {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding: 10px 0px 0px 0px;
        min-height: 48px;
      }

      .v-expansion-panel-content {
        overflow-y: auto;
        // max-height: 440px;
        .v-expansion-panel-content__wrap {
          padding: 0px;
          .cr-workspace-col {
            min-height: 60px;
            .cr-workspace {
              cursor: pointer;
              height: 100%;
              border-radius: 8px;
              background: rgba(0, 0, 0, 0.05);

              .cr-todo-title {
                min-width: 0px;
                line-height: 38px;
              }
            }

            .cr-workspace:hover {
              background: rgba(0, 0, 0, 0.1);
            }

            .v-avatar.cr-workspace-avatar-emoji {
              overflow: unset;
              span {
                font-size: 35px;
                line-height: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";
import AddWorkspaceBtn from "./workspace/AddWorkspaceBtn.vue";

export default {
  components: { AddWorkspaceBtn },
  data({ $store }) {
    const myWorkspaces = $store.getters["todoWorkspace/myWorkspaces"];
    const otherWorkspaces = $store.getters["todoWorkspace/otherWorkspaces"];
    const oepnPanels = [];
    if (myWorkspaces.length > 0) oepnPanels.push(0);
    if (otherWorkspaces.length > 0) oepnPanels.push(1);

    return {
      oepnPanels,
      panels: [
        {
          title: "나의 워크스페이스",
          prefix: "my",
          description: "사용자님이 팔로우하고 있는 모든 워크스페이스입니다."
        },
        {
          title: "다른 워크스페이스",
          prefix: "other",
          description: "사용자님이 팔로우 할 수 있는 모든 워크스페이스입니다."
        }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("todoWorkspace", ["otherWorkspaces", "myWorkspaces"]),
    ...mapGetters("tutorial", ["useWorkflow"]),
    isEmptyWorkspaces() {
      return prefix => this[`${prefix}Workspaces`].length == 0;
    },
    showOtherWorkspaces() {
      return prefix =>
        prefix === "my" ||
        (prefix === "other" && this.workspaces(prefix).length > 0);
    },
    workspaces() {
      return prefix => this[`${prefix}Workspaces`];
    },
    workspaceCls() {
      return idx => {
        let cls = "cr-workspace d-flex align-center px-3 justify-space-between";
        if (!this.$vuetify.breakpoint.xs && idx % 2 == 0) {
          cls += " mr-3";
        }

        return cls;
      };
    }
  },
  watch: {
    myWorkspaces({ length }) {
      const index = this.oepnPanels.indexOf(0);
      if (length > 0 && index === -1) this.oepnPanels.push(0);
      else if (length === 0 && index > -1) this.oepnPanels.splice(index, 1);
    },
    otherWorkspaces({ length }) {
      const index = this.oepnPanels.indexOf(1);
      if (length > 0 && index === -1) this.oepnPanels.push(1);
      else if (length === 0 && index > -1) this.oepnPanels.splice(index, 1);
    }
  },
  methods: {
    ...mapMutations("todoDialog", ["SET_DIALOG"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("todoRoute", ["routeWorkspace"]),
    ...mapActions("todoWorkspace", [
      "followWorkspace",
      "unfollowWorkspace",
      "deleteWorkspace"
    ]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    invertColor(color) {
      return invertColor(color);
    },
    clickAddWorkspaceBtn() {
      this.$refs.addBtn.click();
    },
    menuClick(e, workspace, prefix) {
      const { id: workspaceId, ownerId, title } = workspace;
      this.positioningMenu({
        x: e.target.getBoundingClientRect().left + 35,
        y: e.target.getBoundingClientRect().top,
        itemList: [
          {
            label: "워크스페이스 수정",
            hideItem: ownerId !== this.getUserInfo.id,
            func: () =>
              this.SET_DIALOG({
                headline: "워크스페이스 수정",
                show: true,
                type: "addWorkspace",
                params: { workspace }
              })
          },
          {
            label: "워크스페이스 팔로우",
            hideItem: prefix == "my",
            func: () => this.followWorkspace({ workspaceId, title })
          },
          {
            label: "워크스페이스 팔로우 취소",
            hideItem:
              prefix == "other" ||
              (prefix == "my" && ownerId == this.getUserInfo.id),
            func: () =>
              this.confirm({
                message: `${title} 워크스페이스 팔로우를 취소하시겠습니까?`,
                callback: () => this.unfollowWorkspace({ workspaceId, title }),
                showCloseBtn: true
              })
          },
          {
            label: "워크스페이스 삭제",
            hideItem: ownerId !== this.getUserInfo.id,
            func: () =>
              this.confirm({
                message: `${title} 워크스페이스를 삭제 하시겠습니까?`,
                callback: () => this.deleteWorkspace(workspaceId),
                showCloseBtn: true
              })
          }
        ]
      });
    }
  }
};
</script>
