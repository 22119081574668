var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-todo-workspace-list-wrapper"},[_c('div',[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('AddWorkspaceBtn',{ref:"addBtn"})],1),_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","multiple":""},model:{value:(_vm.oepnPanels),callback:function ($$v) {_vm.oepnPanels=$$v},expression:"oepnPanels"}},_vm._l((_vm.panels),function(panel,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOtherWorkspaces(panel.prefix)),expression:"showOtherWorkspaces(panel.prefix)"}],class:i == 1 ? 'mt-5' : ''},[_c('div',{staticClass:"d-flex align-center text-h5 font-weight-regular"},[_vm._v(" "+_vm._s(panel.title)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('div',{staticClass:"d-flex justify-center caption text-pre-wrap"},[_vm._v(" "+_vm._s(panel.description)+" ")])])],1)]),_c('v-expansion-panel-content',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOtherWorkspaces(panel.prefix)),expression:"showOtherWorkspaces(panel.prefix)"}]},[_c('v-row',{staticClass:"ma-0 pb-4"},[_vm._l((_vm.workspaces(panel.prefix)),function(workspace,idx){return _c('v-col',{key:idx,staticClass:"mt-3 pa-0 cr-workspace-col",attrs:{"cols":"12","sm":"6"},on:{"click":function($event){return _vm.routeWorkspace({ workspaceId: workspace.id })}}},[_c('div',{class:_vm.workspaceCls(idx, panel.prefix)},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"0px"}},[_c('v-avatar',{class:workspace.emoji ? 'cr-workspace-avatar-emoji' : '',attrs:{"size":"38","color":workspace.emoji ? 'transparent' : workspace.color}},[_c('span',{staticClass:"text-h7",style:(("color: " + (_vm.invertColor(workspace.color)) + ";"))},[_vm._v(" "+_vm._s(workspace.avatar)+" ")])]),_c('div',{staticClass:"d-flex flex-column pr-2 cr-todo-title ml-2"},[_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"height":"30px"}},[_vm._v(" "+_vm._s(workspace.title)+" ")]),_c('span',[_vm._v(" "+_vm._s(workspace.ownerName)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(workspace.followerCnt > 0),expression:"workspace.followerCnt > 0"}]},[_vm._v(" 외 "+_vm._s(workspace.followerCnt)+"명 ")])])])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.menuClick(e, workspace, panel.prefix); }).apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)])}),(_vm.isEmptyWorkspaces(panel.prefix))?_c('v-col',[_vm._v(" 워크스페이스가 없습니다. ")]):_vm._e()],2)],1)],1)}),1),(
        _vm.isEmptyWorkspaces('my') &&
          !_vm.showOtherWorkspaces('other') &&
          !_vm.useWorkflow
      )?_c('div',{staticClass:"mx-auto mt-8 mb-8 pa-2 pb-1 cr-tutorial-todo-starter",staticStyle:{"position":"relative"}},[_c('img',{attrs:{"src":require("@/todo/assets/workspace.png")}}),_c('div',{staticClass:"my-1",staticStyle:{"position":"absolute","bottom":"38px","left":"38px","right":"38px"}},[_c('v-btn',{staticStyle:{"border-radius":"16px","font-size":"20px"},attrs:{"block":"","depressed":"","height":"70","color":"primary"},on:{"click":_vm.clickAddWorkspaceBtn}},[_c('b',[_vm._v("내 워크스페이스")]),_vm._v(" 만들기 ")])],1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }